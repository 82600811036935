<template>
  <div
    v-if="message.message"
    class="message-template-manufacture"
    :style="{ alignItems: getMine() ? 'flex-end' : 'flex-start' }">
    <div class="flex-column" style="gap: 8px">
      <div v-if="!getMine()" class="name body5-medium">{{ message.name }}</div>
      <div class="message-wrap" :style="{ flexDirection: getMine() ? 'row-reverse' : 'row' }">
        <div v-if="message.is_deleted">
          <div class="message-content" style="background-color: #e3e3e3">
            <div class="body4 sub3">
              {{ user.parnter_id ? '*내부 규정에 의해 삭제된 메세지입니다.' : '삭제된 메세지입니다.' }}
            </div>
          </div>
        </div>
        <template v-else>
          <talk-images v-if="message.imgs && message.imgs.length > 0" :images="message.imgs"></talk-images>
          <div v-else class="message-content">
            <talk-files v-if="message.files && message.files.length > 0" :item="message.files"></talk-files>
            <template v-else>
              <div v-if="message.message.title" class="body2-bold main" style="margin-bottom: 4px">
                {{ message.message.title }}
              </div>
              <div
                class="content body4 main"
                :style="{ whiteSpace: message.default ? 'normal' : 'pre-wrap' }"
                v-html="message.message.content"></div>
              <div class="message-buttons" v-if="message.message.buttons">
                <button
                  v-for="(button, index) in message.message.buttons"
                  :key="`button-${index}`"
                  class="button"
                  :class="['modal_contract', 'fee_complete'].includes(button.link) ? 'is-dark' : 'is-gray2'"
                  @click="clickButton(button.link)">
                  {{ button.title }}
                </button>
              </div>
            </template>
          </div>
          <div class="message-time">{{ datesFormat(message.created_time, 'time_2') }}</div>
          <img
            v-if="getMine() && !message.default && !message.is_deleted"
            class="unselect"
            src="/static/icon/u_trash-alt-s.svg"
            @click="deleteMessage" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import TalkImages from './TalkImages';
import TalkFiles from './TalkFiles';
import UserAPIMixin from '@/mixins/UserAPIMixin';

export default {
  name: 'MessageTemplateManufacture',
  components: {
    TalkImages,
    TalkFiles,
  },
  mixins: [UserAPIMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    clickButton(link) {
      console.log('clickButton', link);
      this.$emit('clickButton', link);
    },
    deleteMessage() {
      this.$emit('deleteMessage', this.message);
    },
    getMine() {
      if (this.message.default) {
        return this.message.user && !this.user.partner_id;
      } else {
        return this.user.partner_id === this.message.partner;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.message-template-manufacture
  display flex
  flex-direction column
  width 100%
  padding-bottom 8px
  .name
    color $main
      margin-bottom 8px
  .message-wrap
    display flex
    gap 4px
    align-items flex-end
    max-width 480px
   .message-content
      flex 1
      background-color white
      border-radius 8px
      padding 10px 12px
      white-space pre-wrap
      .title
        font-size 16px
        font-weight 700
        color $main
        line-height 24px
        margin-bottom 4px
      .content
        font-size 14px
        color $main
    .message-time
      font-size 12px
      color $sub3

  .message-buttons
    display flex
    flex-direction column
    gap 8px
    margin-top 16px
    .button
      border-radius 6px
      border 0
      font-weight bold
      font-size 14px
      height 36px
    .is-gray2
      background-color $gray3
      color $main
</style>
<style lang="stylus">
.default-message-user-wrapper
  display flex
  flex-direction column
  gap 8px
  padding 8px 0
  margin 8px 0
  border-top 1px solid #EEEEEE
  border-bottom 1px solid #EEEEEE
.default-message-user-item
  display grid
  grid-template-columns 100px 1fr
  align-items center
  gap 8px
.message-template-manufacture .message-content .content
  border-right 1px solid $gray
  display flex
  flex-direction column
</style>
