var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"temp-counsel-review"},[_c('div',{staticClass:"temp-counsel-review-content"},[_c('div',{staticClass:"step step2"},[_c('div',{staticClass:"rate-wrapper"},[_c('div',{staticClass:"step-label"},[_vm._v(" "+_vm._s(_vm.reviewType === 'cs' ? '이 제작사와의 상담이 마음에 드셨나요?' : '제작 후기를 남겨주세요')+" ")]),_c('star-rating',{attrs:{"rating":_vm.value.rate,"active-color":"#FF6600","star-size":32,"increment":0.5,"padding":5,"star-points":[
            20,
            34,
            30,
            40,
            31,
            40,
            32,
            40,
            33,
            38,
            33,
            37,
            31,
            25,
            39,
            18,
            40,
            16,
            40,
            15,
            39,
            14,
            38,
            13,
            27,
            12,
            22,
            1,
            21,
            0,
            20,
            0,
            19,
            0,
            18,
            1,
            13,
            12,
            2,
            13,
            1,
            14,
            0,
            15,
            0,
            16,
            1,
            18,
            9,
            25,
            7,
            37,
            7,
            38,
            8,
            40,
            9,
            40,
            10,
            40,
            20,
            34,
          ],"show-rating":false},on:{"rating-selected":(res) => (_vm.value.rate = res)}})],1),(_vm.reviewType !== 'cs')?[_c('div',{staticClass:"review-img-wrapper"},[_c('input-img-uploader-array',{attrs:{"value":_vm.value.imgs,"width":100,"height":100,"limit":10,"countLabel":true},on:{"update:value":function($event){return _vm.$set(_vm.value, "imgs", $event)}}})],1)]:_vm._e(),_c('div',{staticClass:"review-content-wrapper"},[_c('div',{staticClass:"position-relative textarea-container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.content),expression:"value.content"}],staticClass:"textarea",attrs:{"rows":"2","placeholder":"이 파트너와 상담한 후기 또는 의견이 있다면 작성해 주세요.","maxLength":5000},domProps:{"value":(_vm.value.content)},on:{"keyup":(e) => (_vm.value.content = e.target.value),"input":function($event){if($event.target.composing)return;_vm.$set(_vm.value, "content", $event.target.value)}}}),_c('div',{staticClass:"textarea-length"},[_c('span',[_vm._v(_vm._s(_vm.value.content.length)+" / 5000")])])])]),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button is-main body4-medium",on:{"click":_vm.clickReview}},[_vm._v(" "+_vm._s(_vm.reviewType === 'cs' ? '상담' : '제작')+"후기 등록 ")])])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }