<template>
  <div v-if="detail" class="template-manufacture-detail position-relative">
    <div v-if="isMobile" class="mobile-back-header" :class="user.partner_id ? 'partner' : 'user'">
      <img src="/static/icon/u_angle-left-back.svg" alt="" @click="routerBack" />
      <div v-if="!user.partner_id" class="body4-bold main">{{ detail.partner.nickname }}</div>
      <div v-else class="body4-bold main" @click="openCustomerInfoModal">{{ detail.nickname }}</div>
      <div v-if="!user.partner_id" class="dummys"></div>
      <div v-else class="dummys body5-medium sub" @click="openProductionReqModal">제작 요청서</div>
    </div>
    <div v-if="isMobile" class="dummy"></div>
    <!-- 모바일 때문에 분리 -->
    <div class="template-manufacture-info" v-if="detail && isMobile">
      <div class="flex-align" style="gap: 12px">
        <img
          class="product-img unselect"
          :style="imgSize"
          :src="detail.product.img"
          alt=""
          @click.stop="routerPush(`/template_detail?id=${detail.product.id}`)" />
        <div style="width: calc(100% - 52px)">
          <div class="flex-align" style="gap: 4px">
            <div :class="`tag status-${detail.status}`">
              {{ statusKor(detail.status) }}
            </div>
            <div v-if="!isMobile" class="body5 sub2">
              {{ detail.partner.nickname }}
            </div>
            <div v-else class="body3-bold main ellipsis" style="width: 100%; flex: 1">{{ detail.product.name }}</div>
          </div>
          <div v-if="!isMobile" class="body3-bold main">{{ detail.product.name }}</div>
          <div class="body5 sub2" :class="{ 'margin-top-2': isMobile }">{{ detail.simple_desc }}</div>
          <!-- <div class="body5 sub2" :class="{ 'margin-top-2': isMobile }">{{ optionKor(detail.option) }}</div> -->
        </div>
      </div>
      <div class="flex-align button-contract" style="gap: 8px">
        <button class="button is-main header-menu-btn" @click="$refs.meetingModal.open()">
          <svg-icon icon="u_calender" :width="18" :height="18" color="#242428" />
          미팅잡기
        </button>

        <button
          class="button is-dark header-menu-btn"
          v-if="user.partner_id && detail.contracts.length === 0"
          @click="clickContractReg()">
          <svg-icon icon="u_file-alt" :width="18" :height="18" color="#ffffff" />
          계약하기
        </button>
        <button
          class="button is-main header-menu-btn"
          v-else-if="detail.contracts.length > 0"
          @click="clickContractReg(detail.contracts[0])">
          <svg-icon icon="u_file-alt" :width="18" :height="18" color="#242428" />
          계약조건
        </button>

        <button
          v-if="isMobile && !user.partner_id && !detail.counsel_review"
          class="button is-main header-menu-btn"
          style="flex: 1"
          @click="openReviewModal">
          <svg-icon icon="u_edit-alt" :width="18" :height="18" color="#242428" />
          상담후기 등록
        </button>
      </div>
    </div>
    <div class="template-manufacture-container" ref="messagesMobile">
      <div class="content">
        <div class="template-manufacture-info" v-if="detail && !isMobile">
          <div class="flex-align" style="gap: 12px; flex: 1">
            <img
              class="product-img unselect"
              :style="imgSize"
              :src="detail.product.img"
              alt=""
              @click.stop="routerPush(`/template_detail?id=${detail.product.id}`)" />
            <div>
              <div class="flex-align" style="gap: 4px">
                <div :class="`tag status-${detail.status}`">
                  {{ statusKor(detail.status) }}
                </div>
                <div v-if="!isMobile" class="body5 sub2">
                  {{ detail.partner.nickname }}
                </div>
                <div v-else class="body3-bold main ellipsis" style="width: 80%">{{ detail.product.name }}</div>
              </div>
              <div v-if="!isMobile" class="body3-bold main">{{ detail.product.name }}</div>
              <div class="body5 sub2" :class="{ 'margin-top-2': isMobile }">{{ detail.simple_desc }}</div>
              <!-- <div class="body5 sub2" :class="{ 'margin-top-2': isMobile }">{{ optionKor(detail.option) }}</div> -->
            </div>
          </div>
          <div class="flex-align button-contract" style="gap: 8px">
            <button class="button is-main header-menu-btn" @click="$refs.meetingModal.open()">
              <svg-icon icon="u_calender" :width="18" :height="18" color="#242428" />
              미팅잡기
            </button>

            <button
              class="button is-dark header-menu-btn"
              v-if="user.partner_id && detail.contracts.length === 0"
              @click="clickContractReg()">
              <svg-icon icon="u_file-alt" :width="18" :height="18" color="#ffffff" />
              계약하기
            </button>
            <button
              class="button is-main header-menu-btn"
              v-else-if="detail.contracts.length > 0"
              @click="clickContractReg(detail.contracts[0])">
              <svg-icon icon="u_file-alt" :width="18" :height="18" color="#242428" />
              계약조건
            </button>

            <button
              v-if="isMobile && !user.partner_id && !detail.counsel_review"
              class="button is-main header-menu-btn"
              style="flex: 1"
              @click="openReviewModal">
              <svg-icon icon="u_edit-alt" :width="18" :height="18" color="#242428" />
              상담후기 등록
            </button>
          </div>
        </div>
        <div class="flex-column">
          <div class="messages" ref="messages">
            <template v-for="(message, index) in messages">
              <div v-if="showDateDivider(index)" class="date-divider body5 sub3" :key="`date-divider-${index}`">
                {{ formatDateDivider(index) }}
              </div>
              <MessageTemplateManufacture
                :key="`message-${index}`"
                :message="message"
                @clickButton="clickButton"
                @deleteMessage="openDelMsg" />
            </template>
          </div>
          <div v-if="!isMobile" class="message-send-wrapper">
            <input-file :value.sync="form.imgs" sendMode :description="description" multiple />
            <div>
              <lp-textarea
                :value.sync="form.message.content"
                placeholder="내용을 입력해주세요. (ctrl + Enter :전송)"
                :max-length="1000"
                :rows="4"
                :cols="50"
                :sendMode="true"
                @ctrl-enter="handleCtrlEnter" />
              <button class="button is-primary body2-medium send-btn" @click="sendMessage">전송하기</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="detail" class="aside">
        <div v-if="user.partner_id" class="inquiry-status-wrapper">
          <div class="subtitle7 sub3">처리 상태</div>
          <lp-drop-down
            :value.sync="detail.status"
            :options="statusOptions"
            @update:value="updateStatus"
            style="flex: 1" />
        </div>
        <div class="partner-inquiry-info-wrapper">
          <div class="partner-inquiry-toggle-wrapper">
            <button
              v-for="toggle in toggles"
              :key="toggle.id"
              class="toggle-button subtitle5"
              :class="{ 'is-selected': seletedToggle === toggle.id }"
              @click="seletedToggle = toggle.id">
              {{ toggle.value }}
            </button>
          </div>
          <template v-if="seletedToggle === 0">
            <template v-if="!user.partner_id">
              <div class="partner-info-header">
                <div class="body2-medium main text-left" style="flex: 1">{{ detail.partner.nickname }}</div>
                <div class="flex-align body5 sub2" style="gap: 8px">
                  <!-- 법인 사업자 해결해야함 -->
                  <div>{{ businessTypeKor(detail.partner.business_type) }}</div>
                  <div class="line-bar"></div>
                  <div class="flex-align" style="gap: 16px">
                    <div class="flex-align" style="gap: 4px">
                      <img src="/static/icon/u_star-primary.svg" style="width: 14px" />
                      <div class="body5-medium main">
                        {{ detail.partner.rate
                        }}<span class="sub3"
                          >({{ detail.partner.review_count ? detail.partner.review_count : 0 }})</span
                        >
                      </div>
                    </div>
                    <div class="flex-align" style="gap: 4px">
                      <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width: 14px" />
                      <div class="body5-medium main">조회수 {{ detail.product.visit | currencyNum }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-column" style="gap: 18px">
                <div class="partner-info-body">
                  <div
                    v-if="detail.partner.operating_time && detail.partner.operating_time !== ''"
                    class="partner-info-body-item">
                    <img
                      src="/static/icon/u_clock-gray.svg"
                      alt=""
                      class="unselect"
                      style="width: 20px; height: 20px" />
                    <div class="flex-align" style="gap: 8px">
                      <div class="body4 sub2">연락가능 시간</div>
                      <div class="subtitle7 main">
                        {{ detail.partner.operating_time }}
                      </div>
                    </div>
                  </div>
                  <div class="partner-info-body-item">
                    <img
                      src="/static/icon/u_comment-gray.svg"
                      alt=""
                      class="unselect"
                      style="width: 20px; height: 20px" />
                    <div class="flex-align" style="gap: 8px">
                      <div class="body4 sub2">평균 응답 시간</div>
                      <div class="subtitle7 main">{{ detail.partner.response_time }} 이내</div>
                    </div>
                  </div>
                  <div class="partner-info-body-item">
                    <img src="/static/icon/u_bag-gray.svg" alt="" class="unselect" style="width: 20px; height: 20px" />
                    <div class="flex-align" style="gap: 8px">
                      <div class="body4 sub2">경력</div>
                      <div class="subtitle7 main">
                        {{ detail.partner.career ? detail.partner.career : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body4 sub ellipsis-4" v-html="detail.partner.info"></div>
              </div>
              <button
                class="button is-gray body4-medium sub"
                style="width: 100%; height: 44px"
                @click="routerPush(`/partner_detail?id=${detail.partner.id}`)">
                파트너 프로필 보기
              </button>
            </template>
            <template v-else>
              <div>
                <div>신청자</div>
                <div>{{ detail.nickname }}</div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="inquiry-request-wrapper">
              <div class="inquiry-request-item">
                <div class="subtitle7 sub3">서비스 이름</div>
                <div class="body4-medium main">{{ detail.service_name }}</div>
              </div>
              <div class="inquiry-request-item">
                <div class="subtitle7 sub3">서비스 한 줄 소개</div>
                <div class="body4-medium main" style="white-space: pre-line">
                  {{ detail.simple_desc }}
                </div>
              </div>
              <div class="inquiry-request-item">
                <div class="subtitle7 sub3">서비스 소개</div>
                <div class="body4-medium main" style="white-space: pre-line; word-break: break-all">
                  {{ detail.info }}
                </div>
              </div>
              <!-- <div class="inquiry-request-item">
                <div class="subtitle7 sub3">제작옵션</div>
                <div class="body4-medium main">
                  {{ optionKor(detail.option) }}
                </div>
              </div> -->
              <div class="inquiry-request-item">
                <div class="subtitle7 sub3">제작예산</div>
                <div class="body4-medium main">
                  {{ setBudget(detail.budget) }}
                </div>
              </div>
              <div class="inquiry-request-item">
                <div class="subtitle7 sub3">희망 소요기간</div>
                <div class="body4-medium main">{{ detail.period }}개월</div>
              </div>
              <div v-if="detail.files && detail.files.length > 0" class="inquiry-request-item">
                <div class="subtitle7 sub3">참고 자료</div>
                <a
                  v-for="(file, idx) in detail.files"
                  :key="`req-file-${idx}`"
                  class="inquiry-request-item-file body4-medium main"
                  :href="file"
                  target="_blank">
                  <img src="/static/icon/u_file-s.svg" alt="" style="width: 20px; height: 20px" />
                  <div class="body3 sub">{{ file.split('/').pop() }}</div>
                </a>
              </div>
              <div class="inquiry-request-item">
                <div class="subtitle7 sub3">서비스 지원환경</div>
                <div class="body4-medium main">{{ envKor(detail.env) }}</div>
              </div>
            </div>
          </template>
        </div>
        <template v-if="!user.partner_id">
          <div v-if="!detail.counsel_review" class="temp-counsel-review-wrapper">
            <temp-counsel-review :product="detail" @done="getData" />
          </div>
          <div v-if="templates.length > 0" class="partner-template-wrapper">
            <card-product-temp
              v-for="(template, idx) in templates"
              :key="`partner-template-${idx}`"
              :card="template"
              device="mobile" />
          </div>
        </template>
        <template v-else>
          <div class="customer-memo-wrapper">
            <div class="h8 main">메모</div>
            <div class="text-area-wrapper body4 main">
              <lp-textarea
                v-if="!isMemo"
                :value.sync="memo"
                placeholder="메모를 입력해주세요"
                :max-length="1000"
                :rows="4"
                :cols="50" />
              <div v-else>
                <div class="body4 main">{{ memo }}</div>
              </div>
              <button v-if="!isMemo" class="button is-dark body4-medium" @click="saveMemo">저장</button>
              <div v-else class="memo-fixed-del-btn">
                <button class="button is-gray body4-medium" @click="editMemo">수정</button>
                <button class="button is-gray body4-medium" @click="deleteMemo">삭제</button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <template v-if="isMobile">
      <portal to="btn-modal">
        <div class="box-mobile-send" id="mobile-send-box">
          <input
            type="file"
            id="file-multi-uploader-mobile"
            @change="onFileChange"
            multiple
            accept="image/gif, image/jpeg, image/png, .docx, .pptx, .xlsx, .hwp, .pdf"
            style="display: none" />
          <label for="file-multi-uploader-mobile" style="height: 24px; font-size: 0; display: inline-block">
            <img src="/static/icon/u_plus-circle2.svg" class="ic-upload" alt="파일 업로드" />
          </label>
          <div
            contenteditable="true"
            id="input-send"
            ref="send"
            data-placeholder="메세지를 입력해주세요."
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"></div>
          <img src="/static/icon/u_exclude-alt.svg" @click="clickSendMobile" alt="전송" />
        </div>
      </portal>
      <div class="spinner" v-if="vSpinner">
        <img src="/static/icon/spinner.gif" alt="스피너" />
      </div>
    </template>
    <temp-review-modal
      v-if="isMobile && !user.partner_id && vTempReviewModal"
      ref="tempReviewModal"
      review-type="cs"
      :product="detail"
      @close="closeReviewModal"
      @done="doneReviewModal"></temp-review-modal>
    <template v-if="isMobile && user.partner_id">
      <production-req-modal ref="productionReqModal" :product="detail" />
      <customer-info-modal
        ref="customerInfoModal"
        :product="detail"
        @saveMemo="saveMemoMobile"
        @deleteMemo="deleteMemo" />
    </template>
    <MeetingModal ref="meetingModal" />
    <ContractRegModal ref="contractRegModal" @reg="onRegContract" />
    <ContractModal ref="contractModal" />
    <div class="del-msg-modal">
      <sweet-modal
        overlay-theme="dark"
        ref="delMsgModal"
        width="340px"
        :hide-close-button="true"
        :enableMobileFullscreen="false">
        <div class="subtitle4">메세지를 삭제하시겠습니까?</div>
        <div class="flex-between body5-medium" style="padding: 34px 16px 0">
          <button class="button body2-bold" style="width: 50%; height: 52px" @click="$refs.delMsgModal.close()">
            취소
          </button>
          <button
            class="button is-primary margin-left-16 body2-bold"
            style="width: 50%; height: 52px"
            @click="deleteMessage">
            확인
          </button>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>
<script>
import MessageTemplateManufacture from '@/components/component/MessageTemplateManufacture.vue';
import SvgIcon from '@/components/component/SvgIcon.vue';
import MeetingModal from '@/components/component/MeetingModal.vue';
import ContractModal from '@/components/component/ContractModal.vue';
import ContractRegModal from '@/components/component/ContractRegModal.vue';
const io = require('socket.io-client');
import UserAPIMixin from '@/mixins/UserAPIMixin';
import LpTextarea from '../component/LpTextArea.vue';
import InputFile from '../module/InputFile.vue';
import { SweetModal } from 'sweet-modal-vue';
import TempCounselReview from '../component/TempCounselReview.vue';
import CardProductTemp from '../component/CardProductTemp.vue';
import PartnerAPIMixin from '@/mixins/PartnerAPIMixin';
import LpDropDown from '@/components/LpDropDown.vue';
import TempReviewModal from '../modal/TempReviewModal';
import ProductionReqModal from '../modal/ProductionReqModal.vue';
import CustomerInfoModal from '../modal/CustomerInfoModal.vue';
export default {
  name: 'TemplateManufactureDetail',
  components: {
    MessageTemplateManufacture,
    LpDropDown,
    SvgIcon,
    MeetingModal,
    ContractModal,
    ContractRegModal,
    LpTextarea,
    InputFile,
    SweetModal,
    TempCounselReview,
    CardProductTemp,
    TempReviewModal,
    ProductionReqModal,
    CustomerInfoModal,
  },
  mixins: [UserAPIMixin, PartnerAPIMixin],
  props: {},
  data() {
    return {
      detail: undefined,
      messages: [],
      groupedMessageIndices: [],
      form: {
        message: { content: '' },
        imgs: [],
      },
      files: [],
      fileLength: 0,
      memo: '',
      description: '*형식) jpg, gif, png, hwp, pdf, pptx, docx, xlsx (최대 5개, 10MB까지 등록)',
      delMsg: undefined,
      seletedToggle: 0,
      toggles: [],
      templates: [],
      isSending: false, // 전송 중 상태를 나타내는 플래그 추가
      statusOptions: [
        { value: 0, label: '상담전' },
        { value: 1, label: '상담중' },
        { value: 2, label: '상담완료' },
        { value: 3, label: '계약확정' },
      ],
      isMemo: false,
      isMemoFixed: false,
      vTempReviewModal: false,
      vSpinner: false,
      cur_page: 1,
      total_page: 1,
      loading: false,
      pageYOffset: 0,
      holdYOffset: 0,
    };
  },
  computed: {
    imgSize() {
      let size = this.isMobile ? 40 : 72;
      const [w, h] = this.imgRate(size);
      return {
        width: w + 'px',
        height: h + 'px',
      };
    },
    mobileHeight() {
      if (!this.isMobile) return {};
      const height = window.visualViewport ? window.visualViewport.height : window.innerHeight;
      return {
        maxHeight: `${height - 229}px`,
      };
    },
  },
  watch: {
    files(n) {
      if (this.fileLength > 0 && n.length === this.fileLength) {
        this.uploadFiles();
      }
    },
    pageYOffset(newValue) {
      if (this.isMobile) {
        this.$nextTick(() => {
          const sendBox = document.getElementById('mobile-send-box');
          if (sendBox) {
            if (newValue !== 0 && this.holdYOffset !== 0 && newValue !== this.holdYOffset) {
              window.scrollTo(0, this.holdYOffset);
            }
          }
        });
      }
    },
  },
  created() {
    if (!this.isLogin) {
      this.toast('로그인 후 이용할 수 있습니다.');
      this.$store.commit('setPrevPath', this.$route.fullPath);
      this.$router.replace('/signin');
      return;
    }
    this.setSocketIO();
    this.getData();
    this.initToggle();
  },
  mounted() {
    // this.getMessages();
    if (this.isMobile) {
      this.hideHappytalkIframe();
      this.$nextTick(() => {
        document.documentElement.style.overflow = 'hidden';
        document.documentElement.style.position = 'fixed';
      });
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy() {
    if (this.isMobile) {
      document.documentElement.style.overflow = 'auto';
      document.documentElement.style.position = '';
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    initScrollEvent() {
      this.$nextTick(() => {
        if (this.isMobile) {
          this.$refs.messagesMobile.addEventListener('scroll', this.onScroll);
        } else {
          this.$refs.messages.addEventListener('scroll', this.onScroll);
        }
      });
    },
    onScroll(e) {
      if (e.target.scrollTop < 100) {
        this.getMessages();
      }
    },
    // 모바일 일 때 해피톡 팝업 숨기기
    hideHappytalkIframe() {
      const checkIframe = setInterval(() => {
        const iframe = document.getElementById('HappytalkIframe');
        if (iframe) {
          iframe.style.display = 'none';
          clearInterval(checkIframe);
        }
      }, 100);
    },
    initToggle() {
      this.seletedToggle = this.user.partner_id ? 1 : 0;
      if (this.user.partner_id) {
        this.toggles = [
          { id: 1, value: '제작 요청서' },
          { id: 0, value: '고객정보/메모' },
        ];
      } else {
        this.toggles = [
          { id: 0, value: '파트너 정보' },
          { id: 1, value: '제작 요청서' },
        ];
      }
    },
    updateStatus(status) {
      this.request.partner
        .patch(`launchpack/template_manufacture_inquiry/${this.$route.query.id}`, { status: status })
        .then(() => {
          this.detail.status = status;
        });
    },
    getData() {
      if (this.user.partner_id && this.$route.query.partner) {
        this.request.partner
          .get(`launchpack/template_manufacture_inquiry/${this.$route.query.id}`)
          .then((res) => {
            this.detail = res.data;
            this.memo = this.detail.memo ? this.detail.memo : '';
            this.isMemo = this.memo ? true : false;
            this.readMsg();
            this.getMessages();
          })
          .catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
              this.toast('접근 권한이 없거나 존재하지 않는 문의입니다.');
              this.$router.push('/'); // 홈 페이지나 다른 적절한 페이지로 리다이렉트
            } else {
              console.error('데이터 로딩 중 오류 발생:', error);
              this.toast('데이터를 불러오는 중 오류가 발생했습니다.');
            }
          });
      } else {
        this.request.user
          .get(`launchpack/template_manufacture_inquiry/${this.$route.query.id}`)
          .then((res) => {
            this.detail = res.data;
            this.readMsg();
            this.getMessages();
            this.getPartnerTemplate();
            if (this.$route.query.contract) {
              this.$nextTick(() => {
                this.clickContractReg(this.detail.contracts[0]);
              });
            }
          })
          .catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
              this.toast('접근 권한이 없거나 존재하지 않는 문의입니다.');
              this.$router.push('/'); // 홈 페이지나 다른 적절한 페이지로 리다이렉트
            } else {
              console.error('데이터 로딩 중 오류 발생:', error);
              this.toast('데이터를 불러오는 중 오류가 발생했습니다.');
            }
          });
      }
    },
    getPartnerTemplate() {
      let params = {
        category: 58,
        page_num: 1,
        page_length: 5,
        partner_id: this.detail.partner.id,
        ordering: 'priority,-id',
        fields:
          'id,name,simple_desc,price,img,imgs,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
      };
      let user_id = this.$store.state.user.user_id;
      this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
        this.templates = res.data.data.filter((i) => i.id !== this.detail.product.id).slice(0, 4);
      });
    },
    setBudget(budget) {
      return (budget * 10000).toLocaleString('ko-KR') + '원';
    },
    clickButton(link) {
      console.log('clickButton', link);
      if (link.includes('meeting')) {
        this.$refs.meetingModal.open(link.split('=')[1]);
      } else if (link === 'modal_contract') {
        this.clickContractReg(this.detail.contracts[0]);
      } else if (link === 'template_market') {
        this.$router.push('/template_home');
      } else if (link === 'fee_complete') {
        // 계약금 입금 완료 처리
        if (this.detail.contracts[0].fee_status === 1) {
          this.toast('이미 계약금 입금 완료 처리되었습니다.');
          return;
        }
        this.request.partner.patch(`launchpack/contract/${this.detail.contracts[0].id}`, { fee_status: 1 }).then(() => {
          this.detail.contracts[0].fee_status = 1;
          this.toast('계약금 입금 완료 처리되었습니다.');
        });
      } else if (link === 'process_guide') {
        // 프로세스 안내
        window.open('https://launchpack.notion.site/107993d155d680cba808e55044f29bd2', '_blank');
      } else if (link === 'modal_contract_request') {
        // 계약 다시 요청
        this.clickContractReg();
      } else if (link === 'contract') {
        if (this.$route.query.partner) {
          this.$router.push('/partner_center/contracts');
        } else {
          this.$router.push('/mypage/my_contract');
        }
      } else if (link.includes('inquiry_reg')) {
        this.$router.push(link);
      } else if (link.includes('https://')) {
        window.open(link, '_blank');
      } else if (link.includes('contract_reject')) {
        if (this.user.partner_id) {
          this.request.partner
            .patch(`launchpack/template_manufacture_inquiry/${this.$route.query.id}`, { status: 2, reject: 'reject' })
            .then(() => {
              this.detail.status = 2;
            });
        } else {
          this.request.user
            .patch(`launchpack/template_manufacture_inquiry/${this.$route.query.id}`, { status: 2, reject: 'reject' })
            .then(() => {
              this.detail.status = 2;
            });
        }
      }
    },
    clickContractReg(item) {
      if (this.user.partner_id && (item === undefined || item.status === 0)) {
        this.$refs.contractRegModal.open(item ? item.id : null);
      } else {
        this.$refs.contractModal.open(item.id);
      }
    },
    onRegContract(params) {
      this.detail.contracts = [params];
    },
    routerBack() {
      this.$router.back();
    },
    readMsg() {
      if (this.user.partner_id && this.$route.query.partner) {
        this.request.partner
          .post(`launchpack/template_manufacture_inquiry/${this.$route.query.id}/message/read`)
          .then((res) => {
            console.log(res);
          });
      } else {
        this.request.user
          .post(`launchpack/template_manufacture_inquiry/${this.$route.query.id}/message/read`)
          .then((res) => {
            console.log(res);
          });
      }
    },
    getMessages() {
      if (this.loading) return;
      console.log('getMessages');
      if (this.cur_page <= this.total_page) {
        this.loading = true;
        this.request.user
          .get(
            `launchpack/template_manufacture_inquiry/${this.$route.query.id}/messages?ordering=-created_time&page_num=${this.cur_page}&page_length=50`
          )
          .then((res) => {
            this.loading = false;
            this.total_page = res.data.total_page;

            res.data.data.forEach((i) => {
              if (this.$route.query.partner && i.message_partner) {
                i.message = i.message_partner;
              }
            });

            let list = res.data.data;
            if (this.user.partner_id) {
              // index 2는 유저용 런치팩 메세지
              list = list.filter((i) => i.index !== 2);
            } else {
              // index 3은 파트너용 런치팩 메세지
              list = list.filter((i) => {
                // 시스템 메시지(index 3) 제외
                if (i.index === 3) return false;

                // 특정 title을 가진 메시지 제외
                const excludeTitles = ['✅ ️수수료 입금이 확인되었습니다.', '👀 ️수수료 입금을 확인중입니다.'];
                if (i.message && i.message.title && excludeTitles.includes(i.message.title)) {
                  return false;
                }

                return true;
              });
              console.log('list', list);
            }
            if (this.cur_page === 1) {
              this.messages = this.messages.concat(list.reverse());
              this.initScrollEvent();

              setTimeout(() => {
                if (this.isMobile) {
                  this.$refs.messagesMobile.scrollTop = this.$refs.messagesMobile.scrollHeight;
                } else {
                  this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
                }
              }, 100);
            } else {
              this.messages = list.reverse().concat(this.messages);
            }
            this.$store.commit('setSubMessages', this.messages);
            console.log('this.messages', this.messages);
            this.cur_page++;
            this.setDateDivider();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    setSocketIO() {
      this.socket = io.connect('https://master-dev.launchpack.co.kr:3000', {
        reconnection: true,
        timeout: 10000,
      });
      setTimeout(() => {
        this.socket.on('message', (msg) => {
          if (this.messages[0].inquiry !== Number(this.$route.query.id)) {
            this.messages = this.$store.state.subMessages;
          }
          console.log('msg', msg);
          if (msg.message && msg.message.inquiry_id !== Number(this.$route.query.id)) return;
          if (msg.type === 'template') {
            if (this.$route.query.partner && msg.message.message_partner) {
              msg.message.message = msg.message.message_partner;
            }
            if (this.messages[0].inquiry === Number(this.$route.query.id)) {
              const isDuplicate = this.messages.some((message) => message.id === msg.message.id);
              if (!isDuplicate) {
                const newMessage = msg.message;

                // 파트너가 아닐 때 특정 title을 가진 메시지 필터링
                if (!this.user.partner_id) {
                  const excludeTitles = ['✅ ️수수료 입금이 확인되었습니다.', '👀 ️수수료 입금을 확인중입니다.'];
                  if (
                    newMessage.message &&
                    newMessage.message.title &&
                    excludeTitles.includes(newMessage.message.title)
                  ) {
                    return;
                  }
                }

                this.messages.push(newMessage);

                // 메시지 추가 후 즉시 날짜 구분선 체크
                const currentIndex = this.messages.length - 1;
                if (currentIndex > 0) {
                  const currentDate = new Date(this.messages[currentIndex].created_time).toDateString();
                  const prevDate = new Date(this.messages[currentIndex - 1].created_time).toDateString();

                  if (currentDate !== prevDate) {
                    this.groupedMessageIndices.push({
                      date: new Date(newMessage.created_time),
                      index: currentIndex,
                    });
                    this.groupedMessageIndices.sort((a, b) => b.date - a.date);
                  }
                }
              }
            }
            console.log(this.messages);
            if (msg.message.message && msg.message.message.contract_id && msg.message.message.status === 0) {
              if (this.detail.contracts.length === 0) {
                this.detail.contracts = [
                  {
                    id: msg.message.message.contract_id,
                    status: 0,
                  },
                ];
              } else if (this.detail.contracts[0].id !== msg.message.message.contract_id) {
                this.detail.contracts[0].id = msg.message.message.contract_id;
                this.detail.contracts[0].status = 0;
              }
            } else if (msg.message.message && msg.message.message.contract_id && msg.message.message.status === 1) {
              this.detail.contracts[0].status = 1;
            } else if (msg.message.message && msg.message.message.contract_id && msg.message.message.status === 101) {
              this.detail.contracts[0].status = 101;
            }

            setTimeout(() => {
              if (this.isMobile) {
                this.$refs.messagesMobile.scrollTop = this.$refs.messagesMobile.scrollHeight;
              } else {
                this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
              }
            }, 100);
            this.readMsg();
          } else if (msg.type === 'delete_message') {
            if (this.messages && Array.isArray(this.messages)) {
              const messageToDelete = this.messages.find((i) => i.id === msg.id);
              if (messageToDelete) {
                messageToDelete.is_deleted = true;
              }
            }
          }
        });
      }, 500);
    },
    openDelMsg(msg) {
      this.delMsg = msg;
      this.$nextTick(() => {
        this.$refs.delMsgModal.open();
      });
    },
    deleteMessage() {
      this.request.user
        .delete(`launchpack/template_manufacture_inquiry/${this.$route.query.id}/message/${this.delMsg.id}`)
        .then(() => {
          this.messages.find((i) => i.id === this.delMsg.id).is_deleted = true;
          this.$refs.delMsgModal.close();
        });
    },
    setDateDivider() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const groupedByDate = {};
      this.messages.forEach((message, index) => {
        const messageDate = new Date(message.created_time);
        messageDate.setHours(0, 0, 0, 0);
        const dateKey = messageDate.toISOString().split('T')[0];

        if (!groupedByDate[dateKey]) {
          groupedByDate[dateKey] = [];
        }
        groupedByDate[dateKey].push({ message, index });
      });

      this.groupedMessageIndices = Object.entries(groupedByDate).map(([dateKey, group]) => {
        const firstMessageInGroup = group[0];
        return { date: new Date(dateKey), index: firstMessageInGroup.index };
      });

      this.groupedMessageIndices.sort((a, b) => b.date - a.date);
    },
    showDateDivider(index) {
      if (!this.messages[index]) return false;

      // 첫 번째 메시지는 항상 날짜 표시
      if (index === 0) return true;

      // 현재 메시지와 이전 메시지의 날짜 비교
      const currentDate = new Date(this.messages[index].created_time).toDateString();
      const prevDate = new Date(this.messages[index - 1].created_time).toDateString();

      return currentDate !== prevDate;
    },
    formatDateDivider(index) {
      const messageDate = new Date(this.messages[index].created_time);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      return this.datesFormat(messageDate, 'date_1');
      // if (messageDate.toDateString() === today.toDateString()) {
      //   return '오늘';
      // } else if (messageDate.toDateString() === yesterday.toDateString()) {
      //   return '어제';
      // } else {
      //   return this.datesFormat(messageDate, 'date_1');
      // }
    },
    businessTypeKor(type) {
      switch (type) {
        case 0:
          return '법인사업자';
        case 1:
          return '개인사업자';
        case 2:
          return '팀';
        case 3:
          return '개인';
      }
    },
    statusKor(status) {
      return {
        0: '상담전',
        1: '상담중',
        2: '상담완료',
        3: '계약확정',
      }[status];
    },
    optionKor(option) {
      return {
        0: '템플릿 그대로 제작',
        1: '템플릿을 바탕으로 수정 제작',
      }[option];
    },
    envKor(env) {
      return {
        0: 'Mobile Web 단독\n(PC로 접속시 Mobile Web 뷰 제공)',
        1: 'PC Web + Mobile Web',
        2: 'PC Web + Mobile Web + Mobile App',
        3: 'Mobile App',
      }[env];
    },
    handleCtrlEnter(content) {
      // 이미 전송 중인지 확인하는 플래그 추가
      if (this.isSending) return;

      this.isSending = true;
      this.form.message.content = content;
      this.sendMessage();
    },
    processFiles(params) {
      const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
      // const officeExtensions = ['hwp', 'pdf', 'pptx', 'docx', 'xlsx', 'zip'];

      if (params.imgs && params.imgs.length > 0) {
        const images = [];
        const files = [];

        params.imgs.forEach((file) => {
          const extension = file.split(';')[0].split('/').pop().toLowerCase();
          if (imageExtensions.includes(extension)) {
            images.push(file);
          } else {
            files.push(file);
          }
        });

        params.imgs = images;
        if (files.length > 0) {
          params.files = files.length === 1 ? files[0] : files;
        }
      }

      return params;
    },
    sendMessage() {
      if ((!this.form.message.content || this.form.message.content === '') && this.form.imgs.length === 0) {
        this.toast('내용을 입력해주세요.');
        this.isSending = false; // 플래그 초기화
        return;
      }
      // 메시지 전송 로직
      let params = this.cloneItem(this.form);
      params = this.processFiles(params);
      params.inquiry_id = this.$route.query.id;
      params.user_id = this.detail.user;
      if (this.user.partner_id) {
        params.partner_id = this.user.partner_id;
        params.user_id = null;
      }
      // this.setLoading();
      this.request.user
        .post(`launchpack/template_manufacture_inquiry/${this.$route.query.id}/messages`, params)
        .then(() => {
          this.form.message.content = '';
          this.form.imgs = [];
          // this.clearLoading();
          this.isSending = false; // 전송 완료 후 플래그 초기화
        })
        .catch(() => {
          this.clearLoading();
          this.toast('메시지 전송에 실패했습니다.');
          this.isSending = false; // 에러 발생 시에도 플래그 초기화
        });
    },
    saveMemo() {
      let params = {
        inquiry_id: this.$route.query.id,
        memo: this.memo,
      };
      if (!this.isMemoFixed) {
        this.request.partner.post(`launchpack/template_manufacture_inquiry/memo`, params).then(() => {
          this.toast('메모가 저장되었습니다.');
          this.isMemo = true;
        });
      } else {
        this.request.partner
          .patch(`launchpack/template_manufacture_inquiry/memo/${this.$route.query.id}`, params)
          .then(() => {
            this.toast('메모가 수정되었습니다.');
            this.isMemo = true;
          });
      }
    },
    editMemo() {
      this.isMemo = false;
      this.isMemoFixed = true;
    },
    deleteMemo() {
      this.request.partner.delete(`launchpack/template_manufacture_inquiry/memo/${this.$route.query.id}`).then(() => {
        this.toast('메모가 삭제되었습니다.');
        this.memo = '';
        this.isMemo = false;
        this.isMemoFixed = false;
      });
    },
    openCustomerInfoModal() {
      this.$nextTick(() => {
        this.$refs.customerInfoModal.open();
      });
    },
    openProductionReqModal() {
      this.$nextTick(() => {
        this.$refs.productionReqModal.open();
      });
    },
    openReviewModal() {
      this.vTempReviewModal = true;
      this.$nextTick(() => {
        this.$refs.tempReviewModal.open();
      });
    },
    closeReviewModal() {
      setTimeout(() => {
        this.vTempReviewModal = false;
      }, 300);
    },
    doneReviewModal() {
      this.getData();
      setTimeout(() => {
        this.vTempReviewModal = false;
      }, 300);
    },
    onFileChange(e) {
      this.fileLength = e.target.files.length;
      const files = Array.from(e.target.files);
      if (files.length === 0) return;

      if (files.length > 5) {
        this.toast('파일을 최대 5개 이하로 선택해주세요');
        return;
      }

      const totalSize = this.files.reduce((sum, file) => sum + file.size, 0);
      const maxSize = 50 * 1024 * 1024; // 50MB

      files.forEach((file) => {
        if (totalSize + file.size > maxSize) {
          this.toast('총 파일 크기가 50MB를 초과할 수 없습니다.');
          return;
        }

        if (this.files.some((item) => item.name === file.name && item.size === file.size)) {
          this.toast(`'${file.name}'은(는) 이미 업로드된 파일입니다.`);
          return;
        }

        let reader = new FileReader();
        reader.onload = (f) => {
          const ext = file.name.split('.').pop();
          this.files.push({
            name: file.name,
            file: f.target.result,
            size: file.size,
            extension: ext,
          });
        };
        reader.readAsDataURL(file);
      });
      console.log(this.files);
    },
    async uploadFiles() {
      const extImgs = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'];

      let arr = [
        {
          imgs: this.files
            .filter((i) => {
              return extImgs.indexOf(i.extension) > -1;
            })
            .map((i) => {
              return i.file;
            }),
        },
        {
          files: this.files
            .filter((i) => {
              return extImgs.indexOf(i.extension) === -1;
            })
            .map((i) => {
              return i.file;
            }),
        },
      ];

      this.vSpinner = true;
      for (let params of arr) {
        if (params[Object.keys(params)[0]].length > 0) {
          try {
            // 메시지 전송 로직
            params.inquiry_id = this.$route.query.id;
            params.user_id = this.detail.user;
            params.message = { content: '' };
            if (this.user.partner_id) {
              params.partner_id = this.user.partner_id;
              params.user_id = null;
            }
            let res = await this.request.user.post(
              `launchpack/template_manufacture_inquiry/${this.$route.query.id}/messages`,
              params
            );
            this.messages.splice(0, 0, ...res.data.msgs);
            this.readMsg();
          } catch {
            console.log('err');
          }
        }
      }
      this.vSpinner = false;
      this.fileLength = 0;
      this.files = [];
    },
    clickSendMobile() {
      this.form.message.content = this.$refs.send.innerText;
      this.sendMessage();
      this.$refs.send.innerText = '';
    },
    saveMemoMobile(memo) {
      this.memo = memo;
      this.$nextTick(() => {
        this.saveMemo();
      });
    },
    handleInput(event) {
      if (event.target.textContent.trim() === '') {
        event.target.dataset.empty = 'true';
      } else {
        event.target.dataset.empty = 'false';
      }
    },
    // ios 모바일 키보드 나타나는 이슈
    handleFocus() {
      document.documentElement.style.position = '';
      setTimeout(() => {
        this.holdYOffset = window.pageYOffset;
      }, 100);
    },
    // ios 모바일 키보드 나타나는 이슈
    handleBlur() {
      document.documentElement.style.position = 'fixed';
      this.holdYOffset = 0;
    },
    handleScroll() {
      this.pageYOffset = window.pageYOffset;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.mobile-back-header
  display none
.template-manufacture-detail
  padding 60px 0
  display flex
  justify-content center
.template-manufacture-container
  margin 32px 0
  width 1152px
  display grid
  grid-template-columns 70% 30%
  border 1px solid $gray
  border-radius 12px
  .content
    border-right 1px solid $gray
    display flex
    flex-direction column
  .template-manufacture-info
    padding 16px 24px
    display flex
    justify-content space-between
    border-bottom 1px solid $gray
  .tag
    width fit-content
    font-size 12px
    font-weight 700
    color $main
    padding 4px 8px
    border-radius 4px
    &.status-0
      background-color $gray2
    &.status-1
      background-color $primary
      color white
    &.status-2
      background-color $gray2

  .product-img
    border-radius 4px
    border 1px solid $gray2
    object-fit cover

  .messages
    display flex
    flex-direction column
    padding 24px
    overflow-y auto
    height calc(100vh - 320px)
    background-color $gray2



.button-contract
  .button
    height 38px
    font-weight 500
    font-size 14px
    padding 0 24px
    display flex
    align-items center
    gap 4px

.date-divider
  text-align center
  position relative
  margin 32px 0

  &:before, &:after
    content ''
    position absolute
    top 50%
    width calc(50% - 70px)
    height 1px
    background-color $sub5

  &:before
    left 0

  &:after
    right 0

.message-send-wrapper
  display flex
  flex-direction column
  gap 12px
  padding 16px 24px
  border-top 1px solid #EEEEEE
  background-color white
  border-radius 0 0 0 12px
.send-btn
  width 100%
  border-radius 0 0 8px 8px !important

.partner-inquiry-info-wrapper
  display flex
  flex-direction column
  gap 24px
  padding 24px

.partner-inquiry-toggle-wrapper
  display flex
  border 1px solid $gray
  border-radius 8px
  overflow hidden

  .toggle-button
    flex 1
    padding 12px 8px
    border none
    background-color white
    color $sub3
    cursor pointer
    transition background-color 0.3s, color 0.3s
    &.is-selected
      border 1px solid $primary
      color $primary
      border-radius 8px
      padding 11px 7px

.partner-info-header
  display flex
  flex-direction column

.partner-info-body
  display flex
  flex-direction column
  gap 8px
  .partner-info-body-item
    display grid
    grid-template-columns 20px 1fr
    gap 8px
    align-items center

.inquiry-request-wrapper
  display flex
  flex-direction column
  gap 24px
  .inquiry-request-item
    display flex
    flex-direction column
    gap 2px
    .inquiry-request-item-file
      display flex
      align-items center
      gap 8px
      padding 8px
      border-radius 8px
      border 1px solid $gray1

.temp-counsel-review-wrapper
  padding 24px
  background-color #FAFAFA

.partner-template-wrapper
  display flex
  flex-direction column
  gap 16px
  padding 24px

.inquiry-status-wrapper
  display flex
  align-items center
  gap 20px
  padding 24px
  border-bottom 1px solid $gray

.customer-memo-wrapper
  display flex
  flex-direction column
  gap 12px
  padding 24px
  border-top 1px solid $gray

.text-area-wrapper
  display flex
  flex-direction column
  gap 8px

.memo-fixed-del-btn
  display grid
  grid-template-columns 1fr 1fr
  align-items center
  gap 8px

.line-bar
  width 1px
  height 16px
  background-color $gray1

.spinner
  position fixed
  top 50%
  left 50%
  transform translate(-50%, -50%)
  background rgba(0,0,0,0.7)
  display flex
  align-items center
  justify-content center
  border-radius 12px

.dummy
  height 48px
  width 100%

#input-send
  padding 8px 16px
  flex 1
  max-height 120px
  overflow-y auto

#input-send:focus
  outline 0

#input-send[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #888;
}

#input-send[data-empty="true"]:before {
  content: attr(data-placeholder);
  color: #888;
}

@media (max-width: 425px)

  .tag
    width fit-content
    font-size 12px
    font-weight 700
    color $main
    padding 2px 6px !important
    border-radius 4px
    &.status-0
      background-color $gray2
    &.status-1
      background-color $primary
      color white
    &.status-2
      background-color $gray2
  .mobile-back-header
    display grid
    align-items center
    padding 0 16px
    height 48px
    width 100%
    background-color white
    border-bottom 1px solid #E3E3E3
    gap 12px
    position fixed
    top 0
    left 0
    z-index 10
    &.partner
      grid-template-columns 24px 1fr 64px
    &.user
      grid-template-columns 24px 1fr 24px
      justify-items center
  .template-manufacture-detail
    flex-direction column
    justify-content flex-start
    padding 0
  .template-manufacture-container
    display flex
    flex-direction column
    width 100%
    margin 0
    border-radius 0
    border none
    max-height calc(100vh - 229px)
    overflow-y auto
  .aside
    display none
  .template-manufacture-info
    display flex
    flex-direction column
    gap 8px
    padding 12px 16px
    width 100%
    background-color white

  .header-menu-btn
    padding 0 14px !important
    width fit-content !important
  .box-mobile-send
    background-color white
    border-top 1px solid $gray1
    width 100%
    min-height 60px
    display flex
    align-items center
    justify-content space-between
    padding 0 16px

  textarea
    border 0
    padding 12px 16px
    border-radius 8px
    width 100% !important
    resize none

  input[type=file]
    position absolute
    width 0
    height 0
    border 0 !important
    overflow hidden
    clip rect(0, 0, 0, 0)
    white-space nowrap
    clip-path inset(50%)
    border 0

  textarea:focus
    border 0

  .spinner
    width 78px
    height 78px
    img
      width 34px
      height 34px
  .product-img
    border-radius 4px
    border 1px solid $gray2
    object-fit cover
  .template-manufacture-container .messages
    height 100%
    padding-bottom 100px
  .box-mobile-send
    position fixed
    bottom 0
    left 0
    right 0
</style>
