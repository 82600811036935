<template>
  <div>
    <!-- 레이블 -->
    <div class="label" v-if="label">
      {{ label.replace('*', '') }} <span class="error" v-if="validate && validate.valid_required">*</span>
    </div>

    <div class="c-time-content" style="display: flex; align-items: center">
      <div
        @click.stop.prevent="openPicker('min')"
        :class="[{ 'disabled-text': disabled }, { 'sub3': !min || min === '' }]"
        class="time-picker-wrapper">
        <SvgIcon icon="u_clock-three" :width="24" :height="24" color="#aeaeae"/>
        {{ !min || min === '' ? '00:00' : min }}
      </div>
      <div class="sub2" style="margin: 0 12px">~</div>
      <div
        @click.stop.prevent="openPicker('max')"
        :class="[{ 'disabled-text': disabled }, { 'sub3': !max || max === '' }]"
        class="time-picker-wrapper">
        <SvgIcon icon="u_clock-three" :width="24" :height="24" color="#aeaeae"/>
        {{ !max || max === '' ? '00:00' : max }}
      </div>
    </div>
    <portal to="time-picker">
      <transition name="fade">
        <time-picker-as v-if="vPicker.min" @close="vPicker.min = false" :value.sync="min" @update:value="onUpdateMin"></time-picker-as>
        <time-picker-as v-if="vPicker.max" @close="vPicker.max = false" :value.sync="max" :min="min"></time-picker-as>
      </transition>
    </portal>
  </div>
</template>

<script>
import TimePickerAs from './TimePickerAs';
import SvgIcon from './SvgIcon';
export default {
  name: 'CInputTimeRange',
  components: {
    TimePickerAs,
    SvgIcon,
  },
  props: {
    timePickerIcon: {
      type: Object,
      required: false,
    },
    minuteStep: {
      type: Number,
      default: 5,
    },
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.initValue();
  },
  watch: {
    value() {
      this.initValue();
    },
    min() {
      this.setValue();
    },
    max() {
      this.setValue();
    },
  },
  computed: {
    
  },
  data() {
    return {
      vPicker: {
        min: false,
        max: false,
      },
      min: '',
      max: '',
    };
  },
  methods: {
    onUpdateMin(value) {
      if(value > this.max) {
        let [hours, minutes] = value.split(':').map(Number);
        hours += 1;
        if (hours === 24) {
          hours = 23;
          minutes = 55;
        }
        this.max = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      }
    },
    openPicker(key) {
      if (this.disabled) return;
      this.vPicker[key] = true;
    },
    initValue() {
      this.min = this.value[0];
      this.max = this.value[1];
    },
    setValue() {
      this.value[0] = this.min;
      this.value[1] = this.max;
      this.$emit('update:value', this.value);
    },
  },
};
</script>

<style lang="stylus" type="stylus" scoped>
.c-time-content
  display flex

.fade-enter-active
  transition all .3s

.fade-leave-active
  transition all 3s reverse

.fade-enter, .fade-leave
  opacity 0

.disabled-text
  color #bbb
.time-picker-wrapper
  flex 1
  display flex
  align-items center
  gap 8px
  border 1px solid #ddd
  padding 12px 16px
  border-radius 8px
  cursor pointer
  font-size 15px
  color #242428
</style>
