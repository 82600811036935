<template>
  <div class="meeting-modal">
    <sweet-modal ref="modal" :title="title" overlay-theme="dark">
      <div class="meeting-modal-content">
        <div>
          <div class="label">제목</div>
          <lp-text :value.sync="value.title" :maxLength="50"></lp-text>
        </div>
        <div>
          <div class="label">시간</div>
          <div class="flex" style="flex-direction: column; gap: 8px">
            <input-date :value.sync="value.date"></input-date>
            <input-time-picker-range :value.sync="value.times"></input-time-picker-range>
          </div>
        </div>
        <div v-if="id">
          <div class="label">미팅 링크</div>
          <div class="flex-align" style="gap: 48px">
            <a :href="value.link" target="_blank" class="button is-primary" style="height: 48px; line-height: 48px"
              >온라인 미팅 참여하기</a
            >
            <div class="unselect" @click="copyLink" style="padding-right: 8px; height: 24px">
              <svg-icon icon="u_copy" :width="24" :height="24" color="#828282"></svg-icon>
            </div>
          </div>
        </div>
        <div class="flex-between">
          <div>
            <div class="subtitle6 main">미팅 알림</div>
            <div class="body5 sub3">미팅 1시간 전 알림</div>
          </div>
          <input-toggle :value.sync="value.noti"></input-toggle>
        </div>
        <div>
          <div class="label">비공개 메모</div>
          <lp-text-area
            v-if="$route.query.partner || $route.path.includes('partner_center')"
            :value.sync="value.memo"
            placeholder="입력한 내용은 나만 보여요."
            :maxLength="1000"></lp-text-area>
          <lp-text-area
            v-else
            :value.sync="value.memo_user"
            placeholder="입력한 내용은 나만 보여요."
            :maxLength="1000"></lp-text-area>
        </div>
        <template v-if="value.status !== 3">
          <button v-if="!id" class="button is-primary" @click="reg">Google meet 온라인 미팅등록</button>
          <div v-else class="flex-align" style="gap: 8px">
            <button class="button" @click="clickDelete">삭제</button>
            <button class="button is-primary" @click="clickSave">수정</button>
          </div>
        </template>
      </div>
    </sweet-modal>
    <confirm-modal
      v-if="isModalMounted"
      ref="confirmDelete"
      content="미팅을 삭제하시겠습니까?"
      @done="deleteMeeting"></confirm-modal>
    <confirm-modal
      v-if="isModalMounted"
      ref="confirmUpdate"
      content="미팅을 변경하시겠습니까?"
      @done="save"></confirm-modal>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import LpText from './LpText';
import LpTextArea from './LpTextArea';
import InputDate from '../module/InputDate';
import InputTimePickerRange from './InputTimePickerRange';
import InputToggle from '../module/InputToggle';
import SvgIcon from './SvgIcon';
import UserAPIMixin from '../../mixins/UserAPIMixin';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
import ConfirmModal from './ConfirmModal';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
export default {
  name: 'MeetingModal',
  components: {
    SweetModal,
    LpText,
    LpTextArea,
    InputDate,
    InputTimePickerRange,
    InputToggle,
    SvgIcon,
    ConfirmModal,
  },
  mixins: [UserAPIMixin, PartnerAPIMixin],
  props: {},
  data() {
    return {
      id: null,
      value: {
        title: '',
        date: '',
        times: ['', ''],
        noti: false,
        memo: '',
        memo_user: '',
        status: 0,
        isModalMounted: false,
      },
    };
  },
  computed: {
    title() {
      return this.id ? '미팅 정보' : '미팅 잡기';
    },
    author() {
      return this.$route.query.partner || this.$route.path.includes('partner_center') ? 'partner' : 'user';
    },
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.isModalMounted = true;
    });
  },
  methods: {
    clickDelete() {
      this.$refs.confirmDelete.open();
    },
    clickSave() {
      this.$refs.confirmUpdate.open();
    },
    copyLink() {
      this.$copyText(this.value.link).then(
        () => {
          this.toast('미팅 링크가 복사되었습니다.');
        },
        () => {
          this.toast('미팅 링크 복사에 실패했습니다.');
        }
      );
    },
    init() {
      this.value = {
        title: '',
        date: '',
        times: ['', ''],
        noti: false,
        memo: '',
        memo_user: '',
        status: 0,
      };
      if (this.dayjs().hour() > 20) {
        this.value.date = this.dayjs().add(1, 'day').format('YYYY-MM-DD');
        this.value.times = ['08:00', '09:00'];
      } else {
        this.value.date = this.dayjs().format('YYYY-MM-DD');
        this.value.times = [this.dayjs().add(1, 'hour').format('HH:00'), this.dayjs().add(2, 'hour').format('HH:00')];
      }
    },
    open(id) {
      this.id = id;
      this.init();
      if (id) {
        this.getData();
      }
      this.$refs.modal.open();
    },
    getData() {
      this.request[this.author].get(`launchpack/meeting/${this.id}`).then((res) => {
        this.value = res.data;
        this.value.times = [this.value.start_time.substring(0, 5), this.value.end_time.substring(0, 5)];
      });
    },
    isValid() {
      let msg = '';
      if (!this.value.title) msg = '제목을 입력해주세요.\n';
      if (!msg && !this.value.date) msg = '날짜를 입력해주세요.\n';
      if (!msg && (!this.value.times[0] || !this.value.times[1])) msg = '시간을 입력해주세요.\n';
      if (msg) {
        this.toast(msg);
        return false;
      }
      return true;
    },
    save() {
      if (!this.isValid()) return;
      let params = this.cloneItem(this.value);
      if (this.$route.query.partner) {
        delete params.memo_user;
      } else {
        delete params.memo;
      }
      params.inquiry_id = this.$route.query.id;
      params.start_time = params.times[0];
      params.end_time = params.times[1];
      delete params.times;
      this.setLoading('미팅 수정 중');
      this.request[this.author]
        .patch(`launchpack/meeting/${this.id}`, params)
        .then(() => {
          this.clearLoading();
          this.$refs.modal.close();
          this.$emit('update');
        })
        .catch(() => {
          this.clearLoading();
          this.toast('미팅 수정에 실패했습니다.');
        });
    },
    deleteMeeting() {
      this.setLoading();
      this.request[this.author]
        .delete(`launchpack/meeting/${this.id}`)
        .then(() => {
          this.clearLoading();
          this.$refs.modal.close();
          this.$emit('update');
        })
        .catch(() => {
          this.clearLoading();
          this.toast('미팅 삭제에 실패했습니다.');
        });
    },
    reg() {
      if (!this.isValid()) return;
      let params = this.cloneItem(this.value);
      if (this.$route.query.partner) {
        delete params.memo_user;
      } else {
        delete params.memo;
      }
      params.inquiry_id = this.$route.query.id;
      params.start_time = params.times[0];
      params.end_time = params.times[1];
      delete params.times;
      this.setLoading('미팅 등록 중');
      this.request[this.author]
        .post('launchpack/meeting', params)
        .then(() => {
          this.clearLoading();
          this.$refs.modal.close();
        })
        .catch(() => {
          this.clearLoading();
          this.toast('미팅 등록에 실패했습니다.');
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
.meeting-modal-content
  display flex
  flex-direction column
  gap 32px
  .label
    font-size 15px
    font-weight 500
    margin-bottom 8px
  .button
    width 100%
    height 52px
    font-size 16px
    font-weight 700
    line-height 24px
  .value-content
    font-size 16px
</style>

<style lang="stylus">
.meeting-modal
  .sweet-modal .sweet-title
    border-bottom 0
    padding 16px
    height fit-content
  .sweet-modal .sweet-title > h2
    font-size 18px
    font-weight 700
    line-height 24px
  .sweet-modal .sweet-box-actions
    top 4px
  .sweet-modal .sweet-buttons, .sweet-modal .sweet-content
    padding 16px
@media (min-width: 601px)
  .meeting-modal
    .sweet-modal
      width 380px
      max-height 90vh

@media (max-width: 600px)
  .meeting-modal-content
    padding-bottom 150px
  .meeting-modal
    .sweet-modal
      width 100%
    .sweet-modal .sweet-content .sweet-content-content
      padding 0 16px
</style>
