<template>
  <portal to="confirm-modal">
    <div class="confirm-modal">
      <sweet-modal ref="modal" overlay-theme="dark" width="320px" :enableMobileFullscreen="false" hideCloseButton>
        <div class="confirm-modal-content">{{ content }}</div>
        <div class="flex-align" style="gap: 8px">
          <button class="button" @click="clickCancel">{{ cancelText }}</button>
          <button class="button is-primary" @click="clickDone">
            {{ doneText }}
          </button>
        </div>
      </sweet-modal>
    </div>
  </portal>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
export default {
  name: 'ConfirmModal',
  components: {
    SweetModal,
  },
  mixins: [],
  props: {
    content: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '취소',
    },
    doneText: {
      type: String,
      default: '확인',
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    open() {
      this.$refs.modal.open();
    },
    clickCancel() {
      this.$refs.modal.close();
      this.$emit('cancel');
    },
    clickDone() {
      this.$refs.modal.close();
      this.$emit('done');
    },
  },
};
</script>
<style lang="stylus">
.confirm-modal
  .confirm-modal-content
    font-size 16px
    font-weight 500
    line-height 24px
    color #242428
    padding 20px 0
    margin-bottom 24px
    text-align center

  .button
    flex 1
    height 52px
    font-size 16px
    font-weight 700
    line-height 24px

  .sweet-modal.is-alert .sweet-content
    padding 16px !important
</style>
